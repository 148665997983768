const state = {
  ossKeys: {},
  oss: {},
  tokenExpire: null,
  newExpires: '',
  accessKeyId: '',
  accessKeySecret: '',
  securityToken: '',
  signature: '',
  policy: '',
  policyExpired: null
}

export default state
