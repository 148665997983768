const mutations = {
  setOssKeys (state, data) {
    state.cosKeys = data
  },
  createOss (state, oss) {
    state.oss = oss
  },
  setExpires (state, time) {
    state.expires = time
  },
  setNewExpires (state, time) {
    state.newExpires = time
  },
  setAccessKeyId (state, id) {
    state.accessKeyId = id
  },
  setAccessKeySecret (state, secret) {
    state.accessKeySecret = secret
  },
  setSecurityToken (state, token) {
    state.securityToken = token
  },
  setSignature (state, signature) {
    state.signature = signature
  },
  setPolicy (state, policy) {
    state.policy = policy
  },
  setTokenExpire (state, expire) {
    state.tokenExpire = expire
  },
  setPolicyExpire (state, expire) {
    state.policyExpired = expire
  }
}
export default mutations
