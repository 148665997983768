const getters = {
  get_oss_key (state) {
    return state.ossKeys
  },
  getOss (state) {
    return state.oss
  },
  getAccessKeyId (state) {
    return state.accessKeyId
  },
  getAccessKeySecret (state) {
    return state.accessKeySecret
  },
  getSecurityToken (state) {
    return state.securityToken
  },
  getSign (state) {
    return state.signature
  },
  getPolicy (state) {
    return state.policy
  },
  getTokenExpire (state) {
    return state.tokenExpire
  },
  getPolicyExpire (state) {
    return state.policyExpired
  }
}
export default getters
