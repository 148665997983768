const actions = {
  saveOSS: ({ commit, state }, data) => {
    commit('createOss', data)
  },
  saveOssKeys: ({ commit, state }, data) => {
    commit('setOssKeys', data)
  },
  saveExpires: ({ commit, state }, data) => {
    commit('setExpires', data)
  },
  saveNewExpires: ({ commit, state }, data) => {
    commit('setNewExpires', data)
  },
  saveAccessKeyId: ({ commit, state }, data) => {
    commit('setAccessKeyId', data)
  },
  saveAccessKeySecret: ({ commit, state }, data) => {
    commit('setAccessKeySecret', data)
  },
  saveSecurityToken: ({ commit, state }, data) => {
    commit('setSecurityToken', data)
  },
  saveSignature: ({ commit, state }, data) => {
    commit('setSignature', data)
  },
  savePolicy: ({ commit, state }, data) => {
    commit('setPolicy', data)
  },
  saveTokenExpire: ({ commit, state }, data) => {
    commit('setTokenExpire', data)
  },
  savePolicyExpire: ({ commit, state }, data) => {
    commit('setPolicyExpire', data)
  }
}
export default actions
